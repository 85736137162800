:root {
  --blueMain: #4062ff;
}

@font-face {
  font-family: "Product-Sans-Regular";
  src: local("Product-Sans-Regular"), url(./fonts/Product-Sans-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Product-Sans-Bold";
  src: local("Product-Sans-Bold"), url(./fonts/Product-Sans-Bold.ttf) format("truetype");
}

body {
  margin: 0;
  font-family: "Product-Sans-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.bg-page {
  background: url("./assets/images/bg-app.png");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 768px) {
  .bg-page {
    min-height: 100vh;
    background-repeat: no-repeat !important;
    background-size: cover !important;
  }
}

a{
  color: var(--blueMain) !important;
}

.text-monday {
  color: #f7af31 !important;
}

code {
  font-family: "Product-Sans-Regular";
}
