.text-contract {
    color: white;
    font-size: 80px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 50px;
}

.container-control {
    margin-top: 50px;
    padding-bottom: 100px !important;
}

.token-img{
    width: 150px;
    height: 150px;
    object-fit: contain;
}
.button-monday-club{
    margin-top: 15px;
    width: 300px !important;
    height: 50px;
    border-radius: 8px;
    background-color: #F7AF31;
    color: white;
    font-size: 22px !important;
    font-weight: 700;
    margin-left: auto !important;
    margin-right: auto !important;
    text-align: center;
    font-family: "Product-Sans-Bold";
}

.logo-monday{
    width: 300px;
    height: auto;
    margin-top: 25px;
    margin-bottom: 0px;
    object-fit: contain;
}

.button-load {
    color: black !important;
    font-size: 30px;
    width: 300px;
    border-radius: 8px;
    height: 50px;
    margin-bottom: 30px;
    background-color: white;
}

.background-control {
    background: transparent !important;
}

.border-flex {
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    border-color: red;
    background-color: #020324;
    width: 100%;
    border-width: 1px;
    position: relative;
    padding: 55px;
}
.iscout-header-img {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    object-fit: contain;
}

.iscout-header-title-img {
    width: 20px;
    height: 20px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
    object-fit: contain;
}
.total-sale-text {
    line-height: 90px;
    display: flex;
    font-size: 50px;
    color: white;
    font-weight: 700;
    text-align: center;
    font-family: "Product-Sans-Bold";
}

.total-sale-sub-text {
    font-size: 20px;
    color: white;
    font-weight: 400;
    text-align: center;
    font-family: "Product-Sans-Regular";
}
.grid-allowance-control {
    height: 50px !important;
}

.modal-view-transaction-button {
    background: #ee4023 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    width: 60% !important;
    margin-top: 16px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 50px;
    color: white;
    font-size: 20px;
}

.modal-failed-img {
    width: 80px;
    height: 80px;
    object-fit: contain;
}

.spinner-in-border-control {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-direction: column;
}

.border-process-allowance {
    border: 1px solid #ef4123 !important;
    box-sizing: border-box;
    filter: drop-shadow(0px 12px 20px rgba(12, 30, 52, 0.15));
    border-radius: 4px;
    width: 60%;
    height: 50px;
    margin-left: auto !important;
}

.button-need-approve-disable {
    background-color: #676767 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 16px 40px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 60% !important;
    height: 41px !important;
    color: white;
    font-size: 18px !important;
    font-weight: 400;
    font-family: "Product-Sans-Regular";
}

.steps-flex-control {
    width: 60%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.divider-step {
    width: 80% !important;
    height: 1px;
    margin-top: 30px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 30px;
    border-color: #555962 !important;
}

.number-header {
    font-size: 18px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Product-Sans-Regular";
}

.text-step-header {
    margin-left: 4px;
    font-size: 18px;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    font-family: "Product-Sans-Regular";
}

.input-style {
    display: flex !important;
    width: 100% !important;
    cursor: pointer !important;
    padding: 0px !important;
    margin: 0px !important;
    background-color: #0e1f43 !important;
}

.option-style {
    display: flex !important;
    width: 100% !important;
    cursor: pointer !important;
    margin-top: 8px !important;
    margin-bottom: 8px;
    background-color: #0e1f43 !important;
}

#currency-id {
    height: 0px !important;
}

.selected-style {
    width: 100%;
    margin-top: 16px;
    background: #0e1f43 !important;
    border: 1px solid #555962 !important;
    box-sizing: border-box !important;
    padding: 8px !important;
    border-radius: 4px !important;
}

.selected-green {
    border-color: #03aa4e !important;
}

.selected-grey {
    border-color: #a1a1a1 !important;
}

.enable-step-2 {
    opacity: 1;
}

.disable-step-2 {
    opacity: 0.5;
}
.enable-step-3 {
    opacity: 1;
}

.disable-step-3 {
    opacity: 0.5;
}
.enable-step-4 {
    opacity: 1;
}

.disable-step-4 {
    opacity: 0.5;
}

.enable-step-5 {
    opacity: 1;
}

.disable-step-5 {
    opacity: 0.5;
}

.enable-step-6 {
    opacity: 1;
}

.disable-step-6 {
    opacity: 0.5;
}

.confirm-style {
    margin-top: 16px !important;
    background: #0e1f43 !important;
    border: 1px solid #555962 !important;
    box-sizing: border-box !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
}

.bar-control {
    width: 70%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.total-bar-style {
    width: 100%;
    height: 40px !important;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
}

.progress {
    background-color: #112757 !important;
}
.progress-bar {
    background: linear-gradient(90deg, #004cc6 0%, #c62b2e 45.62%, #ee4123 100%) !important;
}

.text-total-sale-control {
    margin-top: 8px !important;
}
.text-total-sale {
    margin-top: auto !important;
    margin-bottom: auto !important;
    text-align: right;
    margin-left: auto;
    font-size: 18px;
    color: white;
    font-family: "Product-Sans-Regular";
}

.text-header-control {
    width: 70%;
    margin-left: auto !important;
    margin-right: auto !important;
}

.text-header {
    margin-top: 16px;
    font-size: 22px;
    color: white;
    text-align: center;
    font-family: "Product-Sans-Bold";
}

.text-desc {
    margin-top: 16px;
    font-size: 18px;
    color: white;
    text-align: center;
    font-family: "Product-Sans-Regular";
}

.text-desc-header {
    margin-top: 16px;
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 300;
    font-family: "Product-Sans-Regular";
}
.border-bsc-scan-control{
    margin-top: 30px !important;
}
.border-bsc-scan {
    border: 1px solid #ef4123;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: transparent !important;
    padding: 10px 40px;
    color: white;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 20px;
    font-family: "Product-Sans-Bold";
}

.img-iscout-token {
    width: 20px;
    height: 20px;
    object-fit: contain;
}
.text-iscout-style {
    margin-left: 16px;
    font-size: 16px;
    color: white;
    text-align: left;
    font-family: "Product-Sans-Regular";
}
.text-confirm-style {
    font-size: 16px;
    color: white;
    text-align: right;
    font-family: "Product-Sans-Regular";
}

.input-style-1 {
    margin-top: 16px;
    background: #0e1f43 !important;
    border: 1px solid #555962 !important;
    box-sizing: border-box !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    color: white;
    font-size: 16px;
    text-align: right;
    font-family: "Product-Sans-Regular";
}

.text-step-desc {
    font-size: 14px;
    color: #c4c4c4;
    margin-top: auto;
    font-weight: 400;
    margin-bottom: auto;
    font-family: "Product-Sans-Regular";
}

.currency-img {
    width: 18px;
    height: 18px;
    object-fit: contain;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.currency-text {
    color: white !important;
    font-size: 16px !important;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 16px !important;
}

.box-account-control {
    cursor: pointer;
    background-color: #ee4023 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 10px 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px !important;
    display: flex !important;
    color: white;
    font-size: 20px;
    font-family: "Product-Sans-Regular";
}

.border-check-allowance-disable {
    display: none;
}

.border-check-allowance {
    display: flex;
    background-color: transparent !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    margin-left: auto !important;
    width: auto !important;
    height: auto !important;
}

.text-white-contact {
    color: white;
    font-size: 16px;
    font-family: "Product-Sans-Regular";
    margin-top: 40px;
    text-align: center;
}

.link-install-metamask {
    cursor: pointer;
    color: rgb(55, 135, 255) !important;
    font-size: 16px;
    text-decoration: underline;
    font-family: "Product-Sans-Regular";
    margin-left: 4px;
}

.link-change-network {
    cursor: pointer;
    color: white;
    font-size: 16px;
    text-decoration: underline;
    font-family: "Product-Sans-Regular";
    margin-left: 4px;
}

.text-email-contact {
    color: #ee4023;
    font-size: 16px;
    text-decoration: underline;
    font-family: "Product-Sans-Regular";
    margin-left: 4px;
    text-align: center;
}

.text-email-contact:hover {
    color: #ee4023;
    text-decoration: underline;
}

.border-check-investing {
    display: flex;
    background-color: transparent !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 20px !important;
    width: auto !important;
    height: auto !important;
}

.text-check-allowance {
    font-size: 16px !important;
    font-family: "Product-Sans-Regular";
    color: white;
    text-align: center;
}

.loading-allowance {
    margin-left: auto !important;
    margin-right: auto !important;
}
.button-invest-disable {
    background-color: #676767 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 16px 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 258px !important;
    height: 50px !important;
    color: white;
    font-size: 23px !important;
    font-family: "Product-Sans-Regular";
}

.button-need-approve {
    background-color: #ee4023 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 16px 40px;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: auto !important;
    height: 41px !important;
    color: white;
    font-size: 18px !important;
    font-weight: 400;
    font-family: "Product-Sans-Regular";
}

.button-invest {
    background-color: #ee4023 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 16px 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    width: 258px !important;
    height: 50px !important;
    color: white;
    font-size: 23px !important;
    font-family: "Product-Sans-Regular";
}

.button-connect-wallet {
    background-color: #ee4023 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 10px 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    color: white;
    font-size: 20px;
    font-family: "Product-Sans-Regular";
}

.img-coin-total-supply {
    width: 30px;
    margin-top: auto !important;
    margin-bottom: auto !important;
    height: auto;
    margin-left: 4px;
    object-fit: contain;
}

.img-coin {
    position: absolute;
    margin-top: -70px;
    top: 0;
    width: 140px;
    height: auto;
    object-fit: contain;
}

.img-check {
    width: 22px;
    height: 22px;
    margin-left: 6px !important;
    object-fit: contain;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.text-approved {
    color: #03aa4e;
    font-size: 16px !important;
    font-family: "Product-Sans-Regular";
    font-weight: 500 !important;
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
}

.img-approved {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-left: auto !important;
    margin-right: auto !important;
}

.success-message {
    margin-top: 16px !important;
    color: green !important;
    font-size: 23px !important;
    font-family: "Product-Sans-Regular";
    font-weight: 500 !important;
    text-align: center;
}
.error-message {
    color: #ee4023 !important;
    font-size: 16px !important;
    font-family: "Product-Sans-Regular";
    font-weight: 500 !important;
    text-align: center;
}

.height-control {
    min-height: 1000px;
    height: auto !important;
}

.border-amount-green {
    border-color: #03aa4e !important;
}

.border-amount-red {
    border-color: #ee4023 !important;
}

.border-amount-grey {
    border-color: #a1a1a1 !important;
}

.text-amount-limit {
    font-size: 16px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: right;
}

.text-min-amount-red {
    color: #ee4023 !important;
}

.text-min-amount-grey {
    color: #a1a1a1;
}

.text-min-amount-green {
    color: #03aa4e;
}

.text-max-amount-red {
    color: #ee4023;
}

.text-max-amount-grey {
    color: #a1a1a1;
}

.text-max-amount-green {
    color: #03aa4e;
}

.status-approve-control {
    margin-top: 16px !important;
}

.modal-dialog {
    background-color: #030425 !important;
    border-radius: 16px !important;
    padding: 24px 16px;
}

.modal-backdrop.show {
    opacity: 0.7 !important;
}

.modal-add-token-control {
    margin-top: 16px !important;
}

.modal-add-token-text {
    color: white;
    font-size: 17px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: center;
    margin-right: 8px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.add-token-main {
    margin-left: 16px;
    width: 60px;
    height: auto;
    object-fit: contain;
    cursor: pointer;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.section-access-control{
    width: 60%;
    margin-top: 50px !important;
    margin-bottom: 50px !important;
    margin-left: auto !important;
    margin-right: auto !important;
}
.modal-add-token {
    width: 86px;
    height: auto;
    object-fit: contain;
    cursor: pointer;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.modal-close-img {
    cursor: pointer;
    position: relative;
    margin-left: auto;
    width: 20px;
    height: 20px;
    object-fit: contain;
}


.modal-login-input{
    width: 90% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    color: black;
    background-color: white !important;
    margin-top: 10px;
}

.modal-backdrop.show{
    opacity: 1 !important;
}

.modal-backdrop{
    background: url("../../assets/images/bg-app-blur.png") !important;
    filter: blur(5px);
    opacity: 1 !important;
}

.modal-login-img{
    width: 90px;
    height: 90px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 8px;
    object-fit: contain;
}

.modal-login-button-submit{
    background-color: #ee4023 !important;
    box-shadow: 0px 12px 20px rgba(12, 30, 52, 0.15);
    border-radius: 4px;
    padding: 10px 40px;
    width: 90% !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    color: white;
    font-size: 20px;
    font-family: "Product-Sans-Regular";
}


.modal-content {
    background-color: transparent !important;
}

.modal-text-header {
    margin-top: 24px !important;
    color: white;
    font-size: 22px;
    font-family: "Product-Sans-Bold";
    font-weight: 500 !important;
    text-align: center;
}

.modal-text-error-msg {
    margin-top: 16px !important;
    color: red;
    font-size: 17px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: center;
}

.modal-text-desc {
    margin-top: 16px !important;
    color: white;
    font-size: 17px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: center;
}

.modal-text-link {
    cursor: pointer;
    margin-top: 16px !important;
    color: white;
    text-decoration: underline !important;
    font-size: 17px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: center;
}

.modal-control {
    margin-top: 150px;
}

.modal-success-text-total {
    color: white !important;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 16px;
    margin-top: 32px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: left;
}

.modal-success-text-control {
    margin-top: 26px;
    margin-left: 40px !important;
    margin-right: 40px !important;
}

.modal-install-metamask-img {
    width: 80px;
    height: auto;
    object-fit: contain;
}
.modal-success-text-desc {
    color: white !important;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 32px;
    margin-top: 4px;
    font-family: "Product-Sans-Bold";
    font-weight: 500 !important;
    text-align: left;
}
@media screen and (max-width: 768px) {
    .section-access-control{
        width: 100%;
        margin-top: 50px !important;
        margin-bottom: 50px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .border-bsc-scan{
        margin-top: 8px;
        margin-bottom: 8px;
    }
    .text-header-control {
        width: 90%;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .container-control {
        margin-top: 0px;
    }

    .total-sale-text {
        font-size: 40px;
    }

    .total-sale-sub-text {
        font-size: 16px;
    }

    .img-coin {
        position: absolute;
        margin-top: -50px;
        top: 0;
        width: 100px;
        height: auto;
        object-fit: contain;
    }

    .border-flex {
        padding: 40px 24px;
    }
    .steps-flex-control {
        width: 100%;
    }
}
