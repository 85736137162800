

.header-container-control{
    width: 100%;
    min-height: 60px;
    padding-left: 5%;
    padding-right: 5%;
}


.header-logo-control{
    height: 40px;
    width: auto;
    object-fit: contain;
}

.navbar-dark .navbar-toggler{
    border: none !important;
    color: white !important;
    margin-left: auto !important;
}
.navbar-expand-lg .navbar-nav .nav-link{
    padding-left: 16px !important;
    padding-right: 16px !important;
}
.text-menu{
    font-size: 16px;
    color: var(--white) !important;
}
.navbar-collapse{
    width: 100% !important;
}
.navbar-nav{
    margin-left: auto !important;
}
.nav-link{
    margin-left: 16px !important;
    margin-right: 16px !important;
    color: white !important;
    font-family: "Product-Sans-Regular";
    font-weight: 700;
}

.grid-control{
    margin-top: 16px;
    margin-bottom: 16px;
}

.logout-img{
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 4px;
}

.scoutout-logo{
    width: 200px;
    height: auto;
    object-fit: contain;
}


.add-token-control{
    display: flex;
}

.modal-add-token-text{
    color: white;
    font-size: 17px;
    font-family: "Product-Sans-Regular";
    font-weight: 400 !important;
    text-align: center;
    margin-right: 8px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.modal-add-token{
    width: 86px;
    height: auto;
    object-fit: contain;
    cursor: pointer;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

@media (max-width: 768px) {
    .header-logo-control{
        height: 40px;
       
    }
}